const theme = {
  colors: {
    text: "#181818",
    background: "#F4F4F4",
    lightBackground: "#fafafa",
    primary: "#0072CE",
    primaryLight: "rgba(0, 114, 206,.5)",
    secondary: "#181818",
    light: "#ffffff",
    lighten: "rgba(255,255,255,.8)",
    dark: "#000000",
    gray: "#756F6E",
    lightGrey: "rgba(189,189,189,.4)",
    overlay: "rgba(24,24,24,.35)",
  },
  fonts: {
    body: '"neue-haas-grotesk-display", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading:
      '"Px Grotesk", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  },
  space: [
    0, 4, 8, 15, 30, 40, 45, 50, 64, 96, 128, 180, 256, 320, 384, 448, 512,
  ],
  fontSizes: [12, 14, 16, 20, 24, 32, 36, 40, 48, 64, 96, 110, 132],
  fontWeights: {
    body: 400,
    heading: 400,
  },
  lineHeights: {
    body: 1.25,
    heading: 1,
  },
  sizes: {
    container: 1464,
  },
  radii: {
    none: "0",
    xs: ".25rem",
    sm: ".5rem",
    md: "1rem",
    lg: "45px",
    full: "9999px",
  },
  shadows: {
    none: "none",
    default:
      "0 20px 40px -10px rgba(50,50,93,0.15),0 10px 30px -20px rgba(0,0,0,0.15)",
  },
  text: {
    default: {
      lineHeight: "body",
    },
    heading: {
      fontSize: [5],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "text",
    },
    h1: {
      fontSize: [6, 9],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "text",
      mb: 3,
    },
    h2: {
      fontSize: [5, 8, 9, 11], //"4.5rem",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "text",
      mb: [2,2,5],
      mt: [4,4,5],
      em: {
        "-webkit-text-stroke": "1px #181818",
        color: "#181818",
        fontStyle: "normal",
        fontFamily: "body",
        fontWeight:"400"
      },
    },
    article:{
      h2: {
        fontSize: [5, 7], //"4.5rem",
        fontFamily: "heading",
        lineHeight: "heading",
        fontWeight: "heading",
        color: "text",
        mb: [2,2,5],
        mt: [4,4,5],
        em: {
          "-webkit-text-stroke": "1px #181818",
          color: "#181818",
          fontStyle: "normal",
          fontFamily: "body",
          fontWeight:"400"
        },
      },
    },
    h3: {
      fontSize: [4, 8],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "text",
      mb: 3,
      mt: 4,
      em: {
        "-webkit-text-stroke": "1px #181818",
        color: "#181818",
        fontStyle: "normal",
        fontFamily: "body",
        fontWeight:"400"
      },
    },
    h4: {
      fontSize: [4, 6],
      fontWeight: "heading",
      color: "text",
      mb: 2,
      mt: 4,
    },
    h5: {
      fontSize: [4, 5],
      color: "dark",
      fontWeight: "heading",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    h6: {
      fontSize: [2, 4],
      color: "dark",
      fontWeight: "body",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    caption: {
      fontSize: 0,
      color: "gray",
      fontWeight: "400",
    },
    news: {
      fontSize: [2,3],
      lineHeight: [1.2,1.5],
    },
    p:{
      fontSize:[2,4],
      lineHeight:[1.2,1.5],
      my:[3]
    },
    sectionTitle: {
      "h3,h2": {
        margin: "inherit",
        fontFamily: "heading",
        fontSize: [6],
        lineHeight: "1.2",
        fontWeight: 300,
        em: {
          fontStyle: "normal",
          color: "dark",
          fontWeight:"400"
        },
        position: "relative",
        pt: [3],
        pb: [4],
        "&::after": {
          content: "''",
          backgroundColor: "primary",
          position: "absolute",
          top: [0],
          left: [0],
          height: "5px",
          width: "25px",
        },
      },
      h3: {
        margin: "inherit",
        fontFamily: "heading",
        fontSize: [4, 5],
        lineHeight: "1.2",
        fontWeight: 300,
        em: {
          fontStyle: "normal",
          color: "dark",
          fontWeight:"400"
        },
        position: "relative",
        pt: [3],
        pb: [4],
        "&::after": {
          content: "''",
          backgroundColor: "primary",
          position: "absolute",
          top: [0],
          left: [0],
          height: "5px",
          width: "25px",
        },
      },
      "> div": {
        borderBottom: "1px solid",
        borderColor: "dark",
      },
      "> div.light": {
        borderBottom: "1px solid",
        borderColor: "light",
      },
      span: {
        display: "block",
        color: "primary",
        textTransform: "lowercase",
      },
      p: {
        color: "light",
        margin: "inherit",
        mt: [8, 8],
        fontSize: [3, 4],
        lineHeight: "1.5",
        em: {
          fontStyle: "normal",
          color: "primary",
        },
      },
      ul: {
        p: 0,
        listStyleType: "none",
        margin: "inherit",
        fontSize: [1],
        letterSpacing: "2px",
        lineHeight: "1.7",
        em: {
          fontStyle: "normal",
          color: "dark",
        },
        ml: [1],
        li: {
          color: "dark",
          position: "relative",
          "span:before": {
            content: "'//'",
            color: "dark",
            ml: [1],
            position: "absolute",
            left: [-3, -4, -4, -4],
          },
        },
      },
    },
  },
  layout: {
    container: {
      padding: [4, 4],
    },
    sm: {
      maxWidth: "720px",
      padding: [4, 4],
    },
    md: {
      maxWidth: "1020px",
      padding: [4, 4],
    },
    fw: {
      maxWidth: "100%",
      padding: [4, 4],
    },
    header: {
      maxWidth: "100%",
    },
  },
  buttons: {
    fullEmpty: {
      p: [3],
      cursor: "pointer",
      color: "light",
      border: "1px solid",
      borderColor: "light",
      borderRadius: "none",
      "&:hover": {
        color: "primary",
        border: "1px solid",
        borderColor: "light",
        backgroundColor: "light",
      },
    },
    primary: {
      cursor: "pointer",
      color: "light",
      bg: "primary",
      "&:hover": {
        bg: "dark",
      },
    },
    secondary: {
      color: "background",
      bg: "secondary",
    },
  },
  inputs: {
    primary: {
      fontFamily: "heading",
      fontSize: [7, 8, 8, 10],
      color: "light",
      borderRadius: ["58px"],
      px: [3, 4],
      pr: [6, 10],
      "::placeholder": {
        /* Chrome, Firefox, Opera, Safari 10.1+ */ color: "light",
        opacity: 1 /* Firefox */,
      },
    },
    dark: {
      fontFamily: "heading",
      fontSize: [2, 6, 7, 10],
      color: "dark",
      borderRadius: ["58px"],
      py:["18px",0],
      px: [3, 4],
      pr: ["80px", 10],
      "::placeholder": {
        /* Chrome, Firefox, Opera, Safari 10.1+ */ color: "dark",
        opacity: 1 /* Firefox */,
      },
    },
  },
  links: {
    nav: {
      a: {
        fontSize: ["2rem"],
        color: "dark",
        px: [3],
        py: [3],
        border: "1px solid",
        borderColor: "transparent",
        borderRadius: "lg",
        "&.active": {
          border: "1px solid",
          borderRadius: "lg",
          color: "dark",
        },
        "&:hover":{
          backgroundColor:"dark",
          color:"light",
          svg:{
            "#Fill-3":{
              fill: "light"
            },
            fill: "light",
            stroke: "light"
          }
        },
        "&:active":{
          position:"relative",
          border:"1px solid",
          borderColor:"light",
          backgroundColor:"dark",
          color:"light",
          svg:{
            "#Fill-3":{
              fill: "light"
            },
            fill: "light",
            stroke: "light"
          }
        },
        "&:active:before":{
          position:"absolute",
          content:"''",
          width:"calc(100% + 8px)",
          height:"calc(100% + 8px)",
          backgroundColor:"light",
          borderRadius: ["40px","57px"],
          left:"-5px",
          top:"-5px",
          zIndex:-1,
          border:"1px solid",
          borderColor:"dark"
        },

      },
      light: {
        color: "light",
        a: {
          fontSize: [3, 4],
          color: "light",
        },
        "&.active": {
          color: "light",
        },
      },
      dark: {
        color: "dark",
        a: {
          fontSize: [3, 4],
          color: "dark",
        },
        "&.active": {
          color: "dark",
          borderRadius: "lg",
        },
      },
    },
    simple:{
      textDecoration: "none",
                  color: "light",
                  display: "inline-block",
                  textTransform: "uppercase",
                  fontWeight:"bold",
                  fontSize:[2,3]

    },
    dark: {
      cursor:"pointer",
      backgroundColor:"transparent",
      display:"inline-block",
      fontSize: [4,"1.88rem"],
      color: "dark",
      px: [3],
      py: [3],
      border: "1px solid",
      borderColor: "dark",
      textDecoration:"none",
      borderRadius: ["40px","57px"],
      textTransform:"lowercase",
      textAlign:"center",
      lineHeight:"1",
      "&.active": {
        border: "1px solid",
        color: "dark",
      },
      "&:active":{
        position:"relative",
        border:"1px solid",
        borderColor:"light",
        backgroundColor:"dark",
        color:"light"
      },
      "&:active:before":{
        position:"absolute",
        content:"''",
        width:"calc(100% + 8px)",
        height:"calc(100% + 8px)",
        backgroundColor:"light",
        borderRadius: ["40px","57px"],
        left:"-5px",
        top:"-5px",
        zIndex:-1,
        border:"1px solid",
        borderColor:"dark"
      },
      "&:hover":{
        backgroundColor:"dark",
        color:"light",
      },
    },
    tab: {
      textDecoration: "none",
      mr: 3,
      color: "text",
      "&.active": {
        color: "primary",
        fontWeight: "400",
      },
    },
  },
  styles: {
    root: {
      html: {
        scrollBehavior: "smooth",
      },
      "*": {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        "text-rendering": "optimizeLegibility",

        "-webkit-transition": "background-color .2s linear",
        "-ms-transition": "background-color .2s linear",
        transition: "background-color .2s linear",
        "::selection" : {
          "-webkit-text-stroke": "1px transparent",
          color:"light",
          backgroundColor: "primary" /* WebKit/Blink Browsers */
        },
        "::-moz-selection" :{
          "-webkit-text-stroke": "1px transparent",
          color:"light",
          backgroundColor: "primary" /* Gecko Browsers */
        }
      },
      fontFamily: "body",
      fontWeight: "heading",
      "a,button": {
        "-webkit-transition": "background-color .2s linear",
        "-ms-transition": "background-color .2s linear",
        transition: "background-color .2s linear",
        textDecoration: "none",
        color: "primary",
        textDecoration: "underline",
        "&.active": {
          color: "primary",
          //textDecoration: "underline",
        },
        // color: "primary",
        // "&:hover": {
        //   textDecoration: "none",
        // },
      },
      "--swiper-theme-color": "#EA0029",
      ".swiper-container": { pb: 5 },
      ".oneSide": {
        p: [3, 4],
        pr: [3, 0, 0, 0],
        mr: ["auto", 0, 0, 0],
        ml: ["auto", "calc(50vw - 485px)", "calc(50vw - 640px)"],
      },
      "#gatsby-focus-wrapper": {
        position: "relative",
      },
      ".swiper-slide": {
        height: "auto",
      },
      "p":{
        letterSpacing: ".1px"
      }
    },
  },
}

export default theme
